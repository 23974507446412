import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'gatsby';
import Logo from 'components/logo';
import Title from 'components/title';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import MarkdownWrapper from 'components/markdownwrapper';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
// import Gallery from 'components/gallery';

export const CenterItems = styled.div`
  text-align: center;
`;

export const HeroBg = styled.div`
  margin-top: -4rem;
  position: relative;
  height: 300px;
  overflow: hidden;

  ${MEDIA.MIN_TABLET`
    height: 350px;
    max-height: 350px;
  `};

  ${MEDIA.MIN_LAPTOP`
    height: 400px;
    max-height: 400px;
  `};

  ${MEDIA.MIN_DESKTOP`
    height: 700px;
    max-height: 700px;
  `};

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${props =>
      props.fit || 'cover'} !important; object-position: ${props =>
  props.position || '50% 50%'} !important;'
  }

  .gatsby-image-wrapper {
    top: 0;

    ${MEDIA.MIN_PHONE`
      top: -100px;
    `};

    ${MEDIA.MIN_TABLET`
      top: -150px;
    `};

    ${MEDIA.MIN_LAPTOP`
      top: -200px;
    `};

    ${MEDIA.MIN_DESKTOP`
      top: -300px;
    `};
  }
`;

export const HeroInner = styled.div`
  // position: absolute;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
`;

export const HeroTitle = styled.div`
  background-color: #111111;
  z-index: 10;
  position: absolute;
  top: 80%;
  text-align: center;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  display: none;

  ${MEDIA.MIN_TABLET`
    display: block;
  `};

  h1 {
    color: white;
    font-weight: 700;
    font-size: 4.6rem;
    line-height: 1.2;
    font-style: normal;
    text-transform: uppercase;

    ${MEDIA.MIN_LAPTOP`
      font-size: 5.6rem;
    `};
  }

  h2 {
    color: #ffffff;
    font-size: 3rem;
    display: none;

    ${MEDIA.MIN_DESKTOP`
      display: block;
    `};
  }
`;

const Grid = styled.article`
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 300px 1fr;
  grid-gap: 4rem;
  padding: 0;
  margin: 2rem 0;
  display: block;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};

  // ${MEDIA.MIN_LAPTOP`
  //   display: block;
  //   padding: 0 20%;

  //   .article__image {
  //     margin-left: -5rem;
  //     margin-right: -5rem;
  //   }
  // `};
`;

const smallHeading = {
  fontSize: '1.6rem',
  color: '#333333',
};

const articleLink = {
  textDecoration: 'underline',
};

export const LightBg = styled.div`
  padding: 4rem 0;
  background: #fafafa;
  background: -moz-linear-gradient(top, #fafafa 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #fafafa 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #fafafa 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ffffff',GradientType=0 );
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
`;

const Index = ({ data }) => (
  <Layout>
    <HeroBg>
      <Img
        fluid={data.homeJson.hero.childImageSharp.fluid}
        alt="Jet in front of the Fly Jet Center FBO landmark location"
      />
      <HeroInner />
      <HeroTitle>
        <h1>{data.homeJson.title}</h1>
      </HeroTitle>
    </HeroBg>
    {/* <div style={{ height: '5vh' }} />
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <h2 style={{ textAlign: 'center', fontSize: '3rem' }}>
            Style and comfort unmatched by traditional airlines
          </h2>
          <Gallery items={data.homeJson.gallery} />
        </Col>
      </Row>
    </Container> */}
    <div style={{ height: '5vh' }} />
    <div id="home__news">
      <Container>
        <Row>
          <Col sm={12} lg={10} push={{ lg: 1 }}>
            <Title as="h2">Latest News</Title>
            <MarkdownWrapper>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <Grid key={node.id} className="article">
                  <Img
                    className="article__image"
                    fluid={node.frontmatter.teaserImage.childImageSharp.fluid}
                  />
                  <div className="article__content">
                    <h3 className="article__title">
                      {node.frontmatter.title}{' '}
                      <span style={smallHeading}>
                        — {node.frontmatter.date}
                      </span>
                    </h3>
                    <p>{node.excerpt}</p>
                    <Link
                      to={node.frontmatter.slug}
                      className="article__link"
                      style={articleLink}
                    >
                      Read More{' '}
                      <VisuallyHidden>
                        about {node.frontmatter.titleMore}
                      </VisuallyHidden>
                    </Link>
                  </div>
                </Grid>
              ))}
            </MarkdownWrapper>
          </Col>
        </Row>
      </Container>
    </div>
    <div style={{ height: '5vh' }} />
    <LightBg>
      <Container>
        <Row>
          <Col
            sm={12}
            md={5}
            push={{ md: 1 }}
            style={{ textAlign: 'center', marginBottom: '5rem' }}
          >
            <Title as="h3" size="large">
              Services
            </Title>
            <ul>
              <li>Jet Fuel W/Prist</li>
              <li>100ll Aviation Gasoline</li>
              <li>Ground Handling</li>
              <li>Heated Hangar</li>
              <li>Quick Turn Service</li>
              <li>Lavatory Service</li>
              <li>Gourmet Catering</li>
              <li>Oxygen/Nitrogen</li>
              <li>Aircraft Sales</li>
              <li>Maintenance</li>
              <li>Rental Cars and Suburbans</li>
              <li>Pilot Services</li>
            </ul>
          </Col>
          <Col
            sm={12}
            md={5}
            style={{ textAlign: 'center', marginBottom: '5rem' }}
          >
            <Title as="h3" size="large">
              Amenities
            </Title>
            <ul>
              <li>Pilots Lounge</li>
              <li>Conference Room</li>
              <li>Flight Planning Room</li>
              <li>Wireless Internet</li>
              <li>Pilot Supplies</li>
              <li>Passenger Lobby</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </LightBg>
    <div style={{ height: '5vh' }} />
    <Logo />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      hero {
        childImageSharp {
          fluid(maxWidth: 1366, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gallery {
        title
        copy
        path
        image {
          childImageSharp {
            fluid(maxHeight: 500, maxWidth: 722, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
      filter: {
        frontmatter: {
          type: { eq: "article" }
          title: { ne: "" }
          draft: { eq: "false" }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            titleMore
            date(formatString: "MMMM DD, YYYY")
            draft
            type
            slug
            teaserImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1240) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            teaserImageAltText
          }
          fields {
            slug
          }
          excerpt(pruneLength: 172)
        }
      }
    }
  }
`;
